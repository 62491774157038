import { __assign } from "tslib";
import _ from 'lodash';
import createAsyncActions from 'shared/redux/helpers/create-async-actions';
import { getVideoCards } from 'api/public/video-cards/video-cards';
import { VIDEO_CARD_TYPES } from '@wix/wix-vod-constants/dist/video-cards/types';
import { getFullSiteUrl, getInstance, } from 'widget/redux/client/hydrated-data/hydrated-data';
import { buildChannelCardsDeeplinkUrl, buildVideoCardsDeeplinkUrl, } from '@wix/wix-vod-api/dist/src/public/widgets/widgets';
import { createAsyncProxy } from 'shared/worker/lib';
var ACTIONS = createAsyncActions('SERVER.VIDEO-CARDS.GET');
var START = ACTIONS.START, SUCCESS = ACTIONS.SUCCESS, FAIL = ACTIONS.FAIL;
var deeplinkCardTypes = [
    VIDEO_CARD_TYPES.CHANNEL,
    VIDEO_CARD_TYPES.VIDEO,
    VIDEO_CARD_TYPES.RECENT_VIDEO,
];
var hasDeeplink = function (card) { return _.includes(deeplinkCardTypes, card.type); };
var appendDeeplinks = function (_a) {
    var cardsData = _a.cardsData, fallbackUrl = _a.fallbackUrl, instance = _a.instance;
    return _.map(cardsData, function (card) {
        return hasDeeplink(card)
            ? __assign(__assign({}, card), { url: card.actionItemId
                    ? buildVideoCardsDeeplinkUrl({
                        channelId: card.actionListId,
                        videoId: card.actionItemId,
                        instance: instance,
                        fallbackUrl: fallbackUrl,
                    })
                    : buildChannelCardsDeeplinkUrl({
                        channelId: card.actionListId,
                        instance: instance,
                        fallbackUrl: fallbackUrl,
                    }) }) : card;
    });
};
export var NAMES = ACTIONS.NAMES;
export var fetchVideoCards = createAsyncProxy('fetchVideoCards', function (listId, itemId) {
    return function (dispatch, getState) {
        var params = { id: itemId };
        var state = getState();
        var instance = getInstance(state);
        var fullSiteUrl = getFullSiteUrl(state);
        dispatch(START(params));
        return dispatch(getVideoCards(listId, itemId))
            .then(function (response) {
            return appendDeeplinks({
                cardsData: response.data,
                fallbackUrl: fullSiteUrl,
                instance: instance,
            });
        })
            .then(function (data) {
            dispatch(SUCCESS(params, data));
            return data;
        })
            .catch(function (errorResponse) {
            dispatch(FAIL(params, errorResponse, {
                analytics: {
                    type: 'error',
                    name: 'shared.video-cards.get',
                },
            }));
        });
    };
});
